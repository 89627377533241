<template>
  <v-container
    fluid
  >
    <v-card
      class="mt-4"
    >
      <v-card-title>
        {{ $i18n.t('$l.app.notAuthorizedAccessToPage') }} {{ $route.params.from }}
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'NotAuthorized'
}
</script>
